.companyInfoSectionWrapper > span:nth-of-type(3) {
    max-width: inherit !important;
    text-align: center;
}

@media only screen and (min-width: 640px) {
    .companyInfoSectionWrapper > span:nth-of-type(3) {
        text-align: initial;
    }
}
